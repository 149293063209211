const geojson = [
  {
    "category": "Informationen",
    "name": "Kirchen",
    "url": "geodata/kirchen.json",
    "icon": "fa-church",
    "color": "orange",
    "width": 10,
    "fillcolor": "orange"
  },
  {
    "category": "Informationen",
    "name": "Kapellen",
    "url": "geodata/kapellen.json",
    "icon": "fa-cross",
    "color": "yellow",
    "width": 10,
    "fillcolor": "orange"
  }
]
module.exports = geojson;
