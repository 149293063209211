const geojson = [
  {
    "category": "Alignments",
    "name": "Kirchen-Alignments",
    "url": "geodata/lines.json",
    "color": "yellow",
    "width": 3
  },
  {
    "category": "Gebiete",
    "name": "Donut - Nationalbank",
    "url": "geodata/donut_nationalbank.json",
    "color": "grey",
    "width": 1
  },
  {
    "category": "Gebiete",
    "name": "Donut - Lindenhof",
    "url": "geodata/donut_lindenhof.json",
    "color": "grey",
    "width": 1
  },
  {
    "category": "Alte Karten",
    "name": "1800 - Rekonstruktion",
    "url": "geodata/zuerich_1800.json",
    "color": "orange",
    "width": 1
  },
]
module.exports = geojson;
