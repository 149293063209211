const constants = {
  // Map Info
  '_mapTitle': 'Okkultes in Zuerich',
  // Map Settings
  '_markercluster': 'off',
  '_initZoom': '',
  '_initLat': '',
  '_initLon': '',
  // Map Controls
  '_mapSearch': 'topright',
  '_mapRouting': 'car',
  '_mapSearchCircleRadius': '20 miles',
  '_mapSearchZoom': 12,
  '_mapZoom': 'topright',
  '_mapMyLocation': 'topright',
  '_menuOpen': ['Orte'],
  '_categorySelected': ['Orte'],
  '_groupSelected': [],
  '_popupDisabled': [],
  '_heatmap': [],
  // Table
  '_tableHeader': false,
  '_tableCategories': ['Kraftorte','Unterirdisch'],
  '_tableColumns': ['Gruppe', 'Image', 'Name', 'Beschreibung', 'Adresse', 'Boviseinheiten', 'Online'],
};

module.exports = constants;

