const baselayers = {
  'Blue Marble':
  {
    'type': 'wms',
    'url': 'https://gibs.earthdata.nasa.gov/wms/epsg3031/best/wms.cgi',
    'layers': 'BlueMarble_ShadedRelief_Bathymetry',
    'tileMatrixSet': '500m',
    'maxZoom': 5,
    'bounds': [[-36.652178, -134.706917], [-36.652178, 45.293083]],
    'format': 'image/jpeg',
    'tileSize': 512,
    'crs': 'EPSG3031',
    'attribution': '&copy; <a href="https://wiki.earthdata.nasa.gov/display/GIBS" target="_blank">NASA EOSDIS GIBS</a>',
    'default': 1
  },
  'Corrected Reflectance (True Color, VIIRS, SNPP)':
  {
    'type': 'wms',
    'url': 'https://gibs.earthdata.nasa.gov/wms/epsg3031/best/wms.cgi',
    'layers': 'VIIRS_SNPP_CorrectedReflectance_TrueColor',
    'tileMatrixSet': '250m',
    'maxZoom': 5,
    'bounds': [[-36.652178, -134.706917], [-36.652178, 45.293083]],
    'format': 'image/jpeg',
    'tileSize': 512,
    'crs': 'EPSG3031',
    'attribution': '&copy; <a href="https://wiki.earthdata.nasa.gov/display/GIBS" target="_blank">NASA EOSDIS GIBS</a>',
    'default': 0
  },
  'Corrected Reflectance (True Color, MODIS, Terra)':
  {
    'type': 'wms',
    'url': 'https://gibs.earthdata.nasa.gov/wms/epsg3031/best/wms.cgi',
    'layers': 'MODIS_Terra_CorrectedReflectance_TrueColor',
    'tileMatrixSet': '250m',
    'maxZoom': 5,
    'bounds': [[-36.652178, -134.706917], [-36.652178, 45.293083]],
    'format': 'image/jpeg',
    'tileSize': 512,
    'crs': 'EPSG3031',
    'attribution': '&copy; <a href="https://wiki.earthdata.nasa.gov/display/GIBS" target="_blank">NASA EOSDIS GIBS</a>',
    'default': 0
  },
  'Corrected Reflectance (True Color, MODIS, Aqua)':
  {
    'type': 'wms',
    'url': 'https://gibs.earthdata.nasa.gov/wms/epsg3031/best/wms.cgi',
    'layers': 'MODIS_Aqua_CorrectedReflectance_TrueColor',
    'tileMatrixSet': '250m',
    'maxZoom': 5,
    'bounds': [[-36.652178, -134.706917], [-36.652178, 45.293083]],
    'format': 'image/jpeg',
    'tileSize': 512,
    'crs': 'EPSG3031',
    'attribution': '&copy; <a href="https://wiki.earthdata.nasa.gov/display/GIBS" target="_blank">NASA EOSDIS GIBS</a>',
    'default': 0
  },
  'LIMA':
  {
    'type': 'wms',
    'url': 'https://nimbus.cr.usgs.gov/arcgis/services/Antarctica/USGS_EROS_Antarctica_LANDSAT/MapServer/WmsServer',
    'layers': 'LIMA_Full_1km',
    'maxZoom': 5,
    'attribution': '&copy; <a href="https://www.gebco.net" target="_blank">GEBCO Compilation Group</a>',
    'crs': 'EPSG3031',
    'default': 0
  },
  'Gebco Ice':
  {
    'type': 'wms',
    'url': 'https://wms.gebco.net/2024/south-polar/mapserv?',
    'layers': 'GEBCO_south_POLAR_VIEW_ice_2024',
    'bounds': [[-35.0, -135.0], [-35.0, 45.0]],
    'maxZoom': 5,
    'attribution': '&copy; <a href="https://www.gebco.net" target="_blank">GEBCO Compilation Group</a>',
    'crs': 'EPSG9354',
    'version': '1.3.0',
    'default': 0
  },
  'Gebco Elevation':
  {
    'type': 'wms',
    'url': 'https://wms.gebco.net/2024/south-polar/mapserv?',
    'layers': 'GEBCO_south_POLAR_VIEW_ice_2024_2',
    'maxZoom': 5,
    'attribution': '&copy; <a href="https://www.gebco.net" target="_blank">GEBCO Compilation Group</a>',
    'crs': 'EPSG9354',
    'version': '1.3.0',
    'default': 0
  },
  'Gebco Subice':
  {
    'type': 'wms',
    'url': 'https://wms.gebco.net/2024/south-polar/mapserv?',
    'layers': 'GEBCO_south_POLAR_VIEW_bed_2024',
    'maxZoom': 5,
    'attribution': '&copy; <a href="https://www.gebco.net" target="_blank">GEBCO Compilation Group</a>',
    'crs': 'EPSG9354',
    'version': '1.3.0',
    'default': 0
  },
  'Gebco Subice Elevation':
  {
    'type': 'wms',
    'url': 'https://wms.gebco.net/2024/south-polar/mapserv?',
    'layers': 'GEBCO_south_POLAR_VIEW_bed_2024_2',
    'maxZoom': 5,
    'attribution': '&copy; <a href="https://www.gebco.net" target="_blank">GEBCO Compilation Group</a>',
    'crs': 'EPSG9354',
    'version': '1.3.0',
    'default': 0
  },
}
module.exports = baselayers;
