const geojson = [
  {
    "category": "Informationen",
    "name": "Höhlen",
    "url": "geodata/hoehlen.json",
    "icon": "fa-icicles",
    "color": "grey"
  },
  {
    "category": "Informationen",
    "name": "Kapellen",
    "url": "geodata/kapellen.json",
    "icon": "fa-church",
    "color": "green",
    "width": 10,
    "fillcolor": "green"
  },
  {
    "category": "Informationen",
    "name": "Kirchen",
    "url": "geodata/kirchen.json",
    "icon": "fa-church",
    "color": "orange",
    "width": 10,
    "fillcolor": "orange"
  },
  {
    "category": "Informationen",
    "name": "Alles",
    "url": "geodata/place_of_worship.json",
    "icon": "fa-church",
    "color": "orange",
    "width": 10,
    "fillcolor": "orange"
  }
]
module.exports = geojson;
