const tilelayers = [
  {
    'type': 'wms',
    'category': 'Karten',
    'name': 'Zürich Raster grau',
    'url': 'https://www.ogd.stadt-zuerich.ch/wms/geoportal/Basiskarte_Zuerich_Raster_Grau',
    'layers': 'wmslayers',
    'transparent': true,
    'maxZoom': 29,
    'attribution': 'Quelle: Stadt Zürich',
  },
  {
    'type': 'wms',
    'category': 'Alte Karten',
    'name': '1644 - Hans Conrad Gyger, Schanzenplanung',
    'url': 'https://www.ogd.stadt-zuerich.ch/wms/geoportal/Historische_Karte_von_Hans_Conrad_Gyger___Schanzenplanung__ca._1644_',
    'layers': 'wmslayers',
    'transparent': true,
    'maxZoom': 29,
    'attribution': 'Quelle: Stadt Zürich',
  },
  {
    'type': 'wms',
    'category': 'Alte Karten',
    'name': '1667 - Hans Conrad Gyger',
    'url': 'https://www.ogd.stadt-zuerich.ch/wms/geoportal/Historische_Karte_von_Hans_Conrad_Gyger__1667_',
    'layers': 'wmslayers',
    'transparent': true,
    'maxZoom': 29,
    'attribution': 'Quelle: Stadt Zürich',
  },
  {
    'type': 'wms',
    'category': 'Alte Karten',
    'name': '1793 - Uebersichtsplan (Muellerplan)',
    'url': 'https://www.ogd.stadt-zuerich.ch/wms/geoportal/Historischer_Uebersichtsplan_der_Stadt_Zuerich_um_1793__Muellerplan_',
    'layers': 'wmslayers',
    'transparent': true,
    'maxZoom': 29,
    'attribution': 'Quelle: Stadt Zürich',
  },
  {
    'type': 'wms',
    'category': 'Alte Karten',
    'name': '1850 - Uebersichtsplan (J. Wild)',
    'url': 'https://wms.zh.ch/WildkarteZHWMS',
    'layers': 'wild',
    'transparent': true,
    'maxZoom': 29,
    'attribution': 'Quelle: Stadt Zürich',
  },
  {
    'type': 'wms',
    'category': 'Alte Karten',
    'name': '1859-1867 - Uebersichtsplan',
    'url': 'https://www.ogd.stadt-zuerich.ch/wms/geoportal/Historischer_Uebersichtsplan_der_Stadt_Zuerich_um_1859_1867__Altstadtkataster_',
    'layers': 'wmslayers',
    'transparent': true,
    'maxZoom': 29,
    'attribution': 'Quelle: Stadt Zürich',
  },
  {
    'type': 'wms',
    'category': 'Alte Karten',
    'name': '1860 - Uebersichtsplan',
    'url': 'https://www.ogd.stadt-zuerich.ch/wms/geoportal/Historischer_Uebersichtsplan_der_Stadt_Zuerich_um_1860',
    'layers': 'wmslayers',
    'transparent': true,
    'maxZoom': 29,
    'attribution': 'Quelle: Stadt Zürich',
  },
  {
    'type': 'wms',
    'category': 'Alte Karten',
    'name': '1864 - Uebersichtsplan',
    'url': 'https://www.ogd.stadt-zuerich.ch/wms/geoportal/Historischer_Uebersichtsplan_der_Stadt_Zuerich_um_1864',
    'layers': 'wmslayers',
    'transparent': true,
    'maxZoom': 29,
    'attribution': 'Quelle: Stadt Zürich',
  },
  {
    'type': 'wms',
    'category': 'Alte Karten',
    'name': '1900 - Uebersichtsplan',
    'url': 'https://www.ogd.stadt-zuerich.ch/wms/geoportal/Historischer_Uebersichtsplan_der_Stadt_Zuerich_um_1900',
    'layers': 'wmslayers',
    'transparent': true,
    'maxZoom': 29,
    'attribution': 'Quelle: Stadt Zürich',
  },
]
module.exports = tilelayers;
